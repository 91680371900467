var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        {
          staticStyle: {
            "border-left": "2px solid black",
            "border-bottom": "2px solid black",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "main-header", attrs: { span: 24 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          "font-weight": "bold",
                          "font-size": "20px",
                        },
                      },
                      [_vm._v(_vm._s(_vm._taskName))]
                    ),
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                      },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "20px",
                            "font-weight": "bold",
                            cursor: "pointer",
                          },
                          on: { click: _vm.goToHome },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "icon",
                              attrs: {
                                t: "1643079214398",
                                viewBox: "0 0 1024 1024",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "p-id": "2048",
                                width: "16",
                                height: "16",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M588.468659 257.265591H123.316451L371.227243 58.55359a31.947267 31.947267 0 1 0-39.614611-49.837737l-319.472671 255.578137v11.501016a30.669376 30.669376 0 0 0 0 4.472617v3.194727a30.669376 30.669376 0 0 0 0 4.472617v11.501016l319.472671 255.578137a31.947267 31.947267 0 1 0 40.253556-49.837737L123.316451 321.160125h465.152208C792.292223 321.160125 958.418011 464.283881 958.418011 640.632795s-166.125789 319.47267-369.949352 319.472671H95.841801a31.947267 31.947267 0 0 0 0 63.894534h492.626858C830.628943 1024 1022.312545 852.123703 1022.312545 640.632795s-191.683602-383.367205-433.843886-383.367204z",
                                  fill: "#ffffff",
                                  "p-id": "2049",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" 返回首页 "),
                        ]
                      ),
                      _vm.currTabName === "form"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "20px",
                                "font-weight": "bold",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab("flow")
                                },
                              },
                            },
                            [_vm._v("切换流程图>>")]
                          )
                        : _vm._e(),
                      _vm.currTabName === "flow"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "20px",
                                "font-weight": "bold",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab("form")
                                },
                              },
                            },
                            [_vm._v("切换表单>>")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 19, offset: 0 } }, [
                _c("div", { staticClass: "main-card" }, [
                  _c(
                    "div",
                    {
                      staticClass: "deal-content",
                      style: { color: _vm.fontColor ? _vm.fontColor : "black" },
                    },
                    [
                      _c("form-viewer", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currTabName == "form",
                            expression: "currTabName == 'form'",
                          },
                        ],
                        ref: "formViewerRef",
                        staticClass: "deal-form",
                        style: { borderColor: _vm.borderColor },
                        attrs: {
                          flowId: _vm._flowConfig.id,
                          btnObj: _vm.currentLayoutBtn,
                          readonly: _vm.readonly,
                        },
                        on: { submit: _vm.handleOperation },
                      }),
                      _c("flow-graph", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currTabName == "flow",
                            expression: "currTabName == 'flow'",
                          },
                        ],
                        ref: "flowGrapgRef",
                        staticClass: "deal-flow",
                        attrs: { taskDataId: _vm.taskDataId, readonly: true },
                      }),
                      _c("div", {
                        staticStyle: { width: "100%", height: "60px" },
                      }),
                      _c(
                        "el-footer",
                        { staticClass: "deal-btns", style: {} },
                        _vm._l(_vm.curOptBtns, function (item) {
                          return _c(
                            "el-button",
                            {
                              key: item.actionKey,
                              staticClass: "deal-btn-item",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleOperation(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.displayActionName))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 5, offset: 0 } }, [
                _c("div", { staticClass: "main-card" }, [
                  _c(
                    "div",
                    { staticClass: "deal-logs" },
                    [_c("flow-logs", { attrs: { data: _vm._logs } })],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_countersign"),
            visible: _vm.assignDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.assignDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.assignModel, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_sign_signer") } },
                [
                  _c("user-transfer", {
                    attrs: {
                      data: _vm.assignModel.identityScope,
                      multiple: true,
                    },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(
                          _vm.assignModel,
                          "identityScope",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_sign_add_comments") } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.assignModel.submitWords,
                      callback: function ($$v) {
                        _vm.$set(_vm.assignModel, "submitWords", $$v)
                      },
                      expression: "assignModel.submitWords",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.assignDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAssign } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_cc"),
            visible: _vm.ccDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ccDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.ccModel, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_CC_person") } },
                [
                  _c("user-transfer", {
                    attrs: { data: _vm.ccModel.identityScope, multiple: true },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.ccModel, "identityScope", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_cc_comments") } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.ccModel.ccWords,
                      callback: function ($$v) {
                        _vm.$set(_vm.ccModel, "ccWords", $$v)
                      },
                      expression: "ccModel.ccWords",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.ccDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleCc } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_turn_to_do"),
            visible: _vm.dgDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dgDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.dgModel, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_transfer_person") } },
                [
                  _c("user-transfer", {
                    attrs: { data: _vm.dgModel.identityScope, multiple: true },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.dgModel, "identityScope", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_transfer_opinion") } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.dgModel.delegateWords,
                      callback: function ($$v) {
                        _vm.$set(_vm.dgModel, "delegateWords", $$v)
                      },
                      expression: "dgModel.delegateWords",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dgDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleDelegate },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_free_return"),
            visible: _vm.freeReturnDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.freeReturnDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.assignModel, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_return_node") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("lang_please_select_a_return_node"),
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.freeReturnNodeId,
                        callback: function ($$v) {
                          _vm.freeReturnNodeId = $$v
                        },
                        expression: "freeReturnNodeId",
                      },
                    },
                    _vm._l(_vm.getHistoryNodes(), function (item) {
                      return _c("el-option", {
                        key: item.nodeId,
                        attrs: {
                          label: item.displayNodeName,
                          value: item.nodeId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.freeReturnDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleReject(null)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._flowConfig.id
        ? _c("report-list", {
            attrs: {
              visible: _vm.printDialogFlag,
              dataId: _vm._currentTask.runtimeId,
              linkId: _vm._flowConfig.id,
            },
            on: {
              "update:visible": function ($event) {
                _vm.printDialogFlag = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }