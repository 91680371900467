
import { Mixins, Component } from 'vue-property-decorator';
import FlowGraph from './flow-graph.vue';
import LogicFlow from '@logicflow/core';
import { Control, Menu, Snapshot } from '@logicflow/extension';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import DateUtil from '@/utils/DateUtil';
import { registerImageNode as registerNode } from '@/components/flow-design/flow-shape/register/index';
@Component({
  name: 'FlowGraphImage'
})
export default class FlowGraphImage extends Mixins(FlowGraph) {
  // 实例化 LogicFlow
  initLogicFlow(): void {
    let dom: any = document.getElementById('flowChartId');
    let width = dom.offsetWidth;
    let height = dom.offsetHeight;
    // 画布配置
    const config: any = {
      container: dom,
      width: width,
      height: height,
      background: {
        backgroundColor: '#ffffff'
      },
      isSilentMode: this.readonly,
      nodeTextEdit: true,
      grid: {
        size: 10,
        visible: true
      },
      keyboard: {
        enabled: true
      },
      edgeTextDraggable: true,
      guards: {
        beforeClone(data: any) {
          return true;
        },
        beforeDelete(data: any) {
          return true;
        }
      },
      idGenerator() {
        return SystemUtil.uuid();
      }
    };

    let designer = new LogicFlow(config);
    // 使用插件
    // LogicFlow.use(Menu);
    if (!this.readonly) {
      let defaultControlItems = designer.extension.control.controlItems;
      designer.extension.control.controlItems = this.buildFlowControl(defaultControlItems);
    } else {
      designer.extension.control.controlItems = [];
    }
    // if (!this.readonly) {
    //   let control = new Control({ lf: designer });
    //   control.controlItems = this.buildFlowControl(control.controlItems); // 构建流程引擎控制器
    //   // LogicFlow.use(Control);
    //   // LogicFlow.use(Snapshot);
    // }
    this.designer = designer;
    // 设置主题
    designer.setTheme({
      circle: {
        r: 20,
        stroke: '#000000',
        outlineColor: '#88f',
        strokeWidth: 1
      },
      rect: {
        outlineColor: '#88f',
        strokeWidth: 2,
        stroke: '#57a3f3'
      },
      polygon: {
        strokeWidth: 1
      },
      polyline: {
        stroke: '#000000',
        hoverStroke: '#000000',
        selectedStroke: '#000000',
        outlineColor: '#000000',
        strokeWidth: 1
      },
      nodeText: {
        color: '#000000'
      },
      edgeText: {
        color: '#000000',
        background: {
          fill: '#f7f9ff'
        }
      }
    });
    this.registerNodes(designer);
    let graphData = this.buildGraph(this.graphData);
    this.renderNodes(designer, graphData);
    this.focusOnCenter();
    this.buildEvent(this.designer);
  }
  // 注册自定义节点类型
  registerNodes(designer: LogicFlow): void {
    registerNode(designer);
  }
  buildEvent(designer) {
    let rect = this.$el.parentElement!.getBoundingClientRect();
    designer.on('custom:config', node => {
      this.activedNode = SystemUtil.cloneDeep(node);
      this.$nextTick(() => {
        this.nodeConfigDialogVisible = true;
      });
    });

    designer.on('node:click', ({ data, e, position }) => {
      this.$store.commit('SET_ACTIVED_LOG_NODE', data);
    });

    designer.on('node:mouseenter', ({ data, e }) => {
      let target = this.$store.getters.processLogs.find(a => a.nodeId === data.id);
      if (target) {
        this.hoveredNode = {
          id: data.id,
          position: {
            top: e.clientY + 'px',
            left: e.clientX + 'px'
          },
          tips: target.taskHistories.map(task => {
            if (task.completed) {
              let d = DateUtil.parse(task.completedDate);
              return {
                text: task.completedUserName,
                time: DateUtil.format(d, 'yyyy-MM-dd HH:mm'),
                description: task.userRemark
              };
            } else {
              let d = DateUtil.parse(task.createDate);
              return {
                text: task.performersName.join(','),
                time: DateUtil.format('yyyy-MM-dd HH:mm', d)
              };
            }
          })
        };
      } else {
        this.hoveredNode = { tips: [], position: {} };
      }
    });
    designer.on('node:mouseleave', (data, e) => {
      this.hoveredNode = { tips: [], position: {} };
    });

    // 处理移动端的滑动事件
    let dom: any = document.getElementById('flowChartId');
    let touchStartX, touchStartY, touchEndX, touchEndY;
    dom.addEventListener(
      'touchstart',
      evt => {
        let touch = evt.touches[0];
        touchStartX = parseInt(touch.pageX);
        touchStartY = parseInt(touch.pageY);
        touchEndX = touchStartX;
        touchEndY = touchStartY;
      },
      false
    );
    dom.addEventListener(
      'touchmove',
      evt => {
        evt.preventDefault();
        let touch = evt.touches[0];
        touchEndX = parseInt(touch.pageX);
        touchEndY = parseInt(touch.pageY);
      },
      false
    );
    dom.addEventListener(
      'touchend',
      evt => {
        let offsetX = touchEndX - touchStartX;
        let offsetY = touchEndY - touchStartY;
        this.designer.translate(offsetX, offsetY);
      },
      false
    );
  }
}
